import WebServiceRequest from "../Api/WebServiceRequest";
class AccountSetSliderRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Account/SetSlider");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}
class AccountGetSliderRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Account/GetSliders");
  }
}
class AccountDeleteSliderRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
  }
  setPriority(priority) {
    super.setTag("Account/DeleteSlider?priority=" + priority);
  }
}

class AccountAddUserByAdminRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Account/AddUserByAdmin");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}

export {
  AccountSetSliderRequest,
  AccountGetSliderRequest,
  AccountDeleteSliderRequest,
  AccountAddUserByAdminRequest,
};
